

















/* eslint-disable quotes */
import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'ContactResult',
  setup(_props, ctx) {
    const hasFailed = ref(false)

    onMounted(() => {
      if (ctx.root.$route.query && ctx.root.$route.query.errors) {
        hasFailed.value = true
      }
    })

    return {
      hasFailed,
      ...useGetters(['content']),
    }
  },
})
