
























































































import { defineComponent, onBeforeMount, Ref } from '@vue/composition-api'
import { useGetters, useActions } from '@u3u/vue-hooks'
import { FirstStep, SecondStep } from '@/inc/store/modules/steps'
import { StorageUtils } from '@/inc/plugins/storage'

import ContactLocation from '@/components/contact/Location.vue'
import ContactSteps from '@/components/contact/Steps.vue'
import ContactStep2 from '@/components/contact/Step2.vue'
import ContactStep3 from '@/components/contact/Step3.vue'
import GPill from '@/components/g/Pill.vue'

export default defineComponent({
  name: 'contact-step',
  components: {
    GPill,
    ContactLocation,
    ContactSteps,
    ContactStep2,
    ContactStep3,
  },
  setup(_props, ctx) {
    const { content } = useGetters(['content'])
    const { $router, $route } = ctx.root
    const { firstStep, secondStep } = useGetters('steps', [
      'firstStep',
      'secondStep',
    ]) as {
      firstStep: Ref<(storage: StorageUtils) => FirstStep>
      secondStep: Ref<(storage: StorageUtils) => SecondStep>
    }
    const { addFirstStep } = useActions('steps', ['addFirstStep']) as {
      addFirstStep: (payload: {
        data: FirstStep
        storage: StorageUtils
      }) => Promise<void>
    }
    const template = $route.name
    const { $storage } = ctx.root.$options

    onBeforeMount(() => {
      // Select showroom based on query
      if (typeof $route.query?.showroom === 'string') {
        const showrooms: {
          title?: string
          label: string
          zip: string
          value: string
        }[] = content.value.step1?.items || content.value.step2?.showrooms || []

        const showroom = showrooms.find(
          item => item.zip === $route.query.showroom
        )

        if (showroom) {
          addFirstStep({
            data: {
              title: showroom.title || showroom.label,
              zip: showroom.zip,
              value: showroom.value,
            },
            storage: ctx.root.$options.$storage as StorageUtils,
          })
        }
      }

      const routeStep = parseInt($route.params.step, 10)
      const step1 = firstStep.value($storage!)
      const step2 = secondStep.value($storage!)
      let currentStep = routeStep

      // To access step 2, user must have completed step1 (showroom)
      if (routeStep >= 2 && !step1) {
        currentStep = 1
      }

      // To access step 3, user must have completed step2 (categories+message)
      if (
        routeStep === 3 &&
        (!step2?.categories?.items.length || !step2?.message)
      ) {
        currentStep = 2
      }

      // There is no further step than 3
      if (routeStep > 3) {
        currentStep = 1
      }

      if (routeStep !== currentStep) {
        $router.replace({
          name: `new-step${currentStep}`,
          params: { ...$route.params, step: `${currentStep}` },
        })
      }
    })

    return {
      content,
      template,
    }
  },
})
